<template>
  <section id="dashboard">
    <!-- <b-row class="match-height">
      <b-col v-if="isAdmin"
        xl="6"
        md="6"
      >
        <b-button @click="getJSON">Aqui</b-button>
        <field-input :value.sync="collection" name="collection"/>
        <field-input :value.sync="doc" name="doc"/>
        <form-render :form.sync="form" :fields="fields" @send="send" :buttonSend="buttonSend"/>
        <pre>{{json}}</pre>
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BRow,
    // BCol
  },
  data() {
    return {
      data: {}
    }
  },
  mounted () {
  },
  created() {
    // data
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
